/**
 * Created by slava on 24.03.17.
 */

var HoseLocation = function (locationId) {
    BaseLocation.call(this, locationId);
};

HoseLocation.prototype = Object.create(BaseLocation.prototype);
HoseLocation.prototype.constructor = HoseLocation;

HoseLocation.prototype.load = function (stored) {
    this.progress = stored.progress || 0;
};

HoseLocation.prototype.getInfo = function () {
    return {
        progress: this.progress
    };
};

HoseLocation.prototype.isCompleted = function () {
    return this.progress >= HoseLocation.LEVELS_PER_LOCATION;
};

HoseLocation.prototype.changeProgress = function (progress) {
    this.setProgress(this.progress + progress);
};

HoseLocation.prototype.setProgress = function (progress) {
    this.progress = progress;
    this.save();
};

HoseLocation.prototype.getProgress = function () {
    return this.progress;
};

HoseLocation.prototype.gamePlayed = function (outcome) {
    if (outcome === GameBase.OUTCOME_VICTORY && !cleverapps.meta.isCompleted()) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.METHA_LEVEL);
        cleverapps.playSession.set(cleverapps.EVENTS.METHA_LEVEL, true);

        this.changeProgress(1);
    }
};

HoseLocation.prototype.getBackgroundBundle = function () {
    if (!bundles.main_background_vertical || !bundles.main_background_horizontal) {
        return;
    }
    return cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? "main_background_vertical" : "main_background_horizontal";
};

HoseLocation.LEVELS_PER_LOCATION = 15;
