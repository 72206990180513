/**
 * Created by olga on 22.01.2025
 */

BoardTutorialHelper = {};

BoardTutorialHelper.listTutorialWords = function (amount) {
    var game = Game.currentGame;
    var words = [];

    for (var i = 0; i < amount; i++) {
        var word = game.board.words[game.board.words.length - i - 1];
        if (game.board.foundWordsSet[word]) {
            continue;
        }

        words.push(word);
    }

    return words;
};

BoardTutorialHelper.getDiscoverWordStep = function (options) {
    options = options || {};
    var scene = cleverapps.scenes.getRunningScene();
    var highlights = [scene.currentView, cleverapps.scenes.getMovingNode(), scene.exclamationView];

    if (options.highlightBoard) {
        highlights = highlights.concat(scene.boardView);
    }

    var force = options.force || {
        text: ""
    };

    var step = {
        name: "discover_word",
        onStart: function () {
            this.word = typeof options.word === "function" ? options.word() : options.word;

            force.finger = function () {
                return {
                    path: Game.currentGame.keypad.findWordIndexes(this.word),
                    pathColor: options.pathColor
                };
            }.bind(this);

            this.force = cleverapps.forces.create(scene.keypadView, force, {
                highlights: highlights,
                importantNodes: [scene.currentView]
            });

            var keypad = Game.currentGame.keypad;
            keypad.on("touchBegan", this.force.stopFinger.bind(this.force), this.force);
            keypad.on("attempt", this.force.resumeFinger.bind(this.force), this.force);
            keypad.on("reset", this.force.resumeFinger.bind(this.force), this.force);
        },

        onExecution: function () {
            this.force.hideForceHints();
        },

        onFinish: function () {
            if (cleverapps.forces.getRunningForce() === this.force) {
                cleverapps.forces.closeRunningForce();
            }
        }
    };

    return new TutorialStep(step);
};