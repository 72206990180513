/**
 * Created by vladislav on 04/06/20.
 */

var KeypadInterface = function () {
    cleverapps.EventEmitter.call(this);
};

KeypadInterface.prototype = Object.create(cleverapps.EventEmitter.prototype);
KeypadInterface.prototype.constructor = KeypadInterface;

KeypadInterface.prototype.markDiscovered = function () {};
KeypadInterface.prototype.showUp = function () {};
KeypadInterface.prototype.status = function () {};
KeypadInterface.prototype.amountKeys = function () {};
KeypadInterface.prototype.reset = function () {};
KeypadInterface.prototype.findWordIndexes = function () {};
KeypadInterface.prototype.stop = function () {};