/**
 * Created by vladislav on 02.02.2020
 */

var HintNotification = function (game) {
    this.game = game;

    if (cleverapps.config.debugMode) {
        HintNotification.TIMEOUT = 10000;
    }

    this.hintBooster = this.game.hintBooster;

    this.competition = this.game.competition;

    Game.currentGame.on("beforeStartActions", this.start.bind(this), this);
};

HintNotification.prototype.start = function () { 
    if (this.game.outcome !== GameBase.OUTCOME_UNKNOWN) {
        return;
    }

    if (this.competition) {
        this.competition.onChangeResultsListeners.hintNotification = this.refresh.bind(this);
        this.refresh();
    }
};

HintNotification.prototype.refresh = function () {
    if (this.timeout) {
        return;
    }

    if (this.game.outcome !== GameBase.OUTCOME_UNKNOWN) {
        return;
    }

    if (this.game.stopped) {
        return;
    }

    if (cleverapps.forces.isRunningForce()) {
        return;
    }

    if (!this.hintBooster.isAvailable()) {
        return;
    }

    var notification = this.chooseNotification();

    if (notification) {
        cleverapps.notification.create(notification);

        var booster = this.hintBooster;
        booster.startHint();
        booster.alarmTimeout = new cleverapps.LongTimeout(booster.stopHint.bind(booster), 5000);

        this.timeout = new cleverapps.LongTimeout(function () {
            this.clearTimeout();

            this.refresh();
        }.bind(this), HintNotification.TIMEOUT);
    }
};

HintNotification.prototype.chooseNotification = function () {
    var place;
    var results = [];
    var maxResult = this.game.level.content.words.length;
    if (this.competition) {
        place = this.competition.calcPlace();
        results.push(this.competition.player.amount);
        results = results.concat(this.competition.results.filter(function (result) {
            return result.id !== connector.platform.getUserID();
        }).map(function (result) {
            return result.amount;
        }));

        if (!results.slice(1).some(function (result) {
            return result < maxResult;
        })) {
            return false;
        }
    }

    for (var i = 0; i < HintNotification.NOTIFICATIONS.length; i++) {
        if (HintNotification.NOTIFICATIONS[i].filter(place, results, maxResult)) {
            return HintNotification.NOTIFICATIONS[i].text;
        }
    }
    return false;
};

HintNotification.prototype.clearTimeout = function () {
    clearTimeout(this.timeout);
    this.timeout = undefined;
};

HintNotification.prototype.stop = function () {
    this.clearTimeout();

    this.hintBooster.stopHint();

    cleverapps.notification.clear();
};

HintNotification.TIMEOUT = 60000;

HintNotification.NOTIFICATIONS = [];

if (cleverapps.config.name === "olympics") {
    HintNotification.NOTIFICATIONS.push({
        text: "UserBehind1",
        filter: function (place, results) {
            return results[0] < results[1];
        }
    });
    HintNotification.NOTIFICATIONS.push({
        text: "UserBehind2",
        filter: function (place, results) {
            return results[0] + 2 <= results[1];
        }
    });
    HintNotification.NOTIFICATIONS.push({
        text: "UserBehind3",
        filter: function (place, results, maxResult) {
            return results[0] + 1 === maxResult && results[1] + 1 === maxResult;
        }
    });
} else {
    HintNotification.NOTIFICATIONS.push({
        text: "HintButtonNotification.text",
        filter: function (place, results) {
            return place === results.length - 1;
        }
    });
}