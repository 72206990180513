/**
 * Created by Ivan on 25.01.2025
 */

RewardTypes.bonusLevel = {
    flyingAnimation: Reward.FULL_SCREEN_CUSTOM_ANIMATION,
    viewClass: BonusLevelReward,

    bundle: function () {
        return ["bonus_level"];
    },

    handler: function (value) {
        var reward = new Reward("hard", value, {
            event: cleverapps.EVENTS.EARN.BONUS_LEVEL_REWARD
        });
        reward.receiveReward();

        return function () {};
    }
};