/**
 * Created by mac on 5/6/17.
 */

cleverapps.styles.COLORS = cleverapps.overrideColors(cleverapps.styles.COLORS, {
    TEXT_COLOR: new cc.Color(76, 49, 18, 255),
    COLOR_BROWN: new cc.Color(71, 3, 16, 255),
    YELLOW: new cc.Color(254, 220, 16, 255)
});

cleverapps.styles.DECORATORS = cleverapps.overrideStyles(cleverapps.styles.DECORATORS, {
    TILE_SHOP_STROKE: {
        color: new cc.Color(0, 0, 0, 255),
        size: 1
    }
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    LOADING_ANIMATION_TEXT: {
        size: 35,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.WINDOW_WHITE_TEXT_STROKE
    },
    REWARDS_TEXT: {
        name: "nostroke",
        size: 30,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.REWARDS_TEXT_STROKE
    },
    PACKWINDOW_LABEL_TEXT: {
        name: "nostroke",
        size: 40,
        color: cleverapps.styles.COLORS.WHITE
    }
});

cleverapps.overrideStyles(cleverapps.styles.CloversView, {
    width: 180,
    height: 70,

    icon: {
        width: 125,

        x: { align: "left", dx: -40 }
    },

    text: {
        x: { align: "center", dx: 30 }
    },

    position: [
        {
            x: { align: "left", dx: 50 },
            y: { align: "bottom", dy: 530 }
        },
        {
            x: { align: "left", dx: 50 },
            y: { align: "bottom", dy: 530 }
        },
        {
            x: { align: "left", dx: 50 },
            y: { align: "bottom", dy: 530 }
        }
    ],

    sparks: {
        x: 10,
        y: 10
    }
});

cleverapps.styles.COLORS = cleverapps.overrideColors(cleverapps.styles.COLORS, {
    PATH_COLOR: new cc.Color(145, 56, 188, 255),
    LETTER: new cc.Color(80, 60, 35, 255),
    COLOR_LIGHTBROWN: new cc.Color(140, 107, 87, 255),
    COLOR_VICTORY: new cc.Color(240, 73, 0, 255),
    DARK_GRAY: new cc.Color(178, 178, 178, 255),
    LIGHT_GREEN_COLOR: new cc.Color(200, 255, 100, 255),
    PLAYER_ALARM_COLOR: new cc.Color(255, 40, 40, 255)
});

cleverapps.styles.DECORATORS = cleverapps.overrideStyles(cleverapps.styles.DECORATORS, {

});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    CONGRATE_TEXT: {
        size: 100,
        color: cleverapps.styles.COLORS.LIGHT_GREEN_COLOR,
        shadow: cleverapps.styles.DECORATORS.WHITE_TEXT_SHADOW
    },

    WIN_TEXT: {
        size: 125,
        color: new cc.Color(0xff, 0xff, 0x31, 0xff),
        stroke: {
            color: new cc.Color(0xae, 0x49, 0x1b, 0xff),
            size: 7
        }
    },

    EPISODE_TITLE_TEXT: {
        size: 80,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    LEVEL_TITLE_TEXT: {
        name: "default",
        size: 48
    },

    LEVEL_NOT_PASSED_TITLE_TEXT: {
        name: "default",
        size: 48,
        color: new cc.Color(140, 140, 140, 255)
    },

    LEVEL_CURRENT_TITLE_TEXT: {
        name: "default",
        size: 48,
        color: cleverapps.styles.COLORS.WHITE
    },

    LETTERS_TEXT: {
        size: 70,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    DESCRIPTION_TEXT: {
        size: 26,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    REWARDS_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.TEXT_COLOR
    }
});

cleverapps.overrideStyles(cleverapps.styles.LevelView, {
    LevelId: {
        y: {
            dy: -7
        }
    },
    clickSize: {
        width: 130,
        height: 120
    }
});

cleverapps.overrideStyles(cleverapps.styles.LevelsView, {
    levels_1: [
        { x: -170, y: 330 },
        { x: 0, y: 330 },
        { x: 170, y: 330 },
        { x: -170, y: 175 },
        { x: 0, y: 175 },
        { x: 170, y: 175 },
        { x: -170, y: 20 },
        { x: 0, y: 20 },
        { x: 170, y: 20 },
        { x: -170, y: -135 },
        { x: 0, y: -135 },
        { x: 170, y: -135 },
        { x: -170, y: -290 },
        { x: 0, y: -290 },
        { x: 170, y: -290 }
    ]
});

cleverapps.overrideStyles(cleverapps.styles.KeypadView, {
    radius: {
        default: 190,
        "3": 160,
        "4": 170,
        "5": 185,
        "6": 200
    },

    controls: {
        width: 100,
        height: 100
    },

    bg: {
        diameter: 120
    }
});

cleverapps.overrideStyles(cleverapps.styles.LocationView, {
    padding: 530,
    minScale: 0.68,

    Background: {
        width: 600,
        height: 990,
        y: 60
    },

    title: {
        y: 490,
        maxWidth: 560
    },

    description: {
        y: 420
    },

    comingSoon: {
        character: {
            x: 0,
            y: -40
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.CurrentView, {
    statusIcon: {
        offsetX: 25
    }
});

cleverapps.overrideStyles(cleverapps.styles.CurrentLetterView, {
    scale: 0.8
});

cleverapps.overrideStyles(cleverapps.styles.LotteryIcon, {
    positions: {
        "icon": {
            x: { align: "right", dx: 50 },
            y: { align: "center", dy: 0 },
            scale: 0.8
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.BoardView, {
    minSpaceX: 50,
    minSpaceY: 25,

    Letter: {
        offset: 4
    }
});

cleverapps.overrideStyles(cleverapps.styles.VkLotteryIcon, {
    positions: {
        "bottom": {
            x: { align: "center", dx: 0 },
            y: { align: "bottom", dy: -60 }
        },
        "icon": {
            x: { align: "left", dx: -20 },
            y: { align: "bottom", dy: -35 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.KeypadLetterView, {
    noBg: true
});

cleverapps.overrideStyles(cleverapps.styles.LevelStartWindow, {
    padding: {
        top: 50,
        bottom: 90,
        left: 135,
        right: 135
    },
    coinsRain: {
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: 0 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PackWindow, {
    animation: {
        "default": {
            idleDelay: 0,
            dx: 0,
            dy: 0
        },

        "starterPack0": {
            idleAnimation: "idle_800",
            introAnimation: "open_800"
        },

        "starterPack": {
            idleAnimation: "idle_2600",
            introAnimation: "open_2600"
        },

        "starterPack2": {
            idleAnimation: "idle_17000",
            introAnimation: "open_17000"
        }
    },

    rewards: {
        delay: 1.6,
        duration: 0.675,

        fitTo: {
            width: 140
        },

        positions: {
            "default": {
                "hard": {
                    x: 0,
                    y: -45
                }
            }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.GameScene, {
    sound: {
        delay: 3.2,
        fadeIn: 1
    }
});

cleverapps.overrideStyles(cleverapps.styles.CharView, {
    topPaddingWithBg: 7
});

cleverapps.overrideStyles(cleverapps.styles.Reward, {
    sidebarDelta: {
        x: { align: "center" },
        y: { align: "center", dy: 125 }
    }
});
