/**
 * Created by Ivan on 25.01.2025
 */

var BonusLevelReward = cc.Node.extend({
    ctor: function () {
        this._super();

        this.setAnchorPoint(0.5, 0.5);

        this.addChest();

        this.setVisible(false);
    },

    addChest: function () {
        var styles = cleverapps.styles.BonusLevelReward;

        var chestPath = bundles.game.jsons.bonus_chest_horizontal_json;
        var chest = this.chest = new cleverapps.Spine(chestPath);
        this.setContentSize2(chest.getContentSize());
        this.setPositionRound(styles.position);

        this.addChild(chest);
        chest.setPositionRound(this.width / 2, this.height / 2);
        chest.setAnimation(0, "animation", true);
    },

    open: function () {
        this.chest.setAnimation(0, "open", false);
    },

    getCollectAnimation: function () {
        return new cc.Sequence(
            new cc.DelayTime(1),
            AnimationsLibrary.showUp(this, { flash: true }),
            new cc.CallFunc(function () {
                this.open();
            }.bind(this)),
            new cc.DelayTime(1),
            AnimationsLibrary.coinCloud(this, {
                reward: Game.currentGame.collectedCoins
            }),
            new cc.DelayTime(1),
            AnimationsLibrary.disappear(this),
            new cc.RemoveSelf()
        );
    }
});

cleverapps.styles.BonusLevelReward = {
    position: {
        x: { align: "center" },
        y: { align: "center" }
    }
};