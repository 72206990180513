/**
 * Created by Andrey Popov on 30.03.20.
 */

cleverapps.CookieJar = function () {
    this.load();
    this.shouldRewardInCurrentLevel = false;
    this.visible = this.shouldBeVisible();

    this.onCookieListener = function () {};
    this.onFullListener = function () {};
    this.onBecomeVisible = function () {};
    this.onResetListener = function () {};
    this.onFoundListener = function () {};
};

cleverapps.CookieJar.prototype.shouldBeVisible = function () {
    return cleverapps.CookieJar.IsAvailable() || this.getCounterValue() !== 0;
};

cleverapps.CookieJar.prototype.setVisible = function () {
    if (!this.visible) {
        this.visible = true;
        this.onBecomeVisible();
    }
};

cleverapps.CookieJar.prototype.incCounter = function () {
    if (this.shouldRewardInCurrentLevel) {
        this.countOfCookies++;
        this.save();

        this.setVisible();
        this.onCookieListener();
    }
};

cleverapps.CookieJar.prototype.almostFill = function () {
    this.countOfCookies = cleverapps.CookieJar.COOKIES_TO_REWARD_GOAL - 1;
};

cleverapps.CookieJar.prototype.isRewardReceived = function () {
    if (this.isFull()) {
        this.onFullListener();
    }
};

cleverapps.CookieJar.prototype.setShouldRewardInCurrentLevel = function (rewardForNew) {
    this.shouldRewardInCurrentLevel = rewardForNew;
};

cleverapps.CookieJar.prototype.save = function (fromServer) {
    cleverapps.dataLoader.save(DataLoader.TYPES.COOKIE_JAR, this.getCounterValue());

    if (!fromServer) {
        cleverapps.synchronizer.addUpdateTask("cookiejar");
    }
};

cleverapps.CookieJar.prototype.getCounterValue = function () {
    return this.countOfCookies;
};

cleverapps.CookieJar.prototype.load = function () {
    var save = cleverapps.dataLoader.load(DataLoader.TYPES.COOKIE_JAR);
    this.countOfCookies = +save || 0;
};

cleverapps.CookieJar.prototype.isFull = function () {
    return this.getCounterValue() >= cleverapps.CookieJar.COOKIES_TO_REWARD_GOAL;
};

cleverapps.CookieJar.prototype.reset = function () {
    this.countOfCookies -= cleverapps.CookieJar.COOKIES_TO_REWARD_GOAL;
    this.save();
};

cleverapps.CookieJar.IsAvailable = function () {
    return !cleverapps.flags.cookieJarDisabled && cleverapps.forces.isShown(Forces.COOKIEJAR.id);
};

cleverapps.CookieJar.COOKIES_TO_REWARD_GOAL = 15;