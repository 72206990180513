/**
 * Created by olga on 26.12.2024
 */

var StacksTutorial = function () {
    return new TutorialScenario("stack_tutorial", {
        listSteps: StacksTutorial.listSteps,
        isAvailable: StacksTutorial.isAvailable
    });
};

StacksTutorial.listSteps = function () {
    var steps = [];

    var amount = BoardTutorialHelper.listTutorialWords(3).length;
    for (var i = 0; i < amount; i++) {
        var options = {};
        options.word = function () {
            return Game.currentGame.keypad.getExistedWords()[0];
        };

        if (i === 0 && !cleverapps.forces.isShown(Forces.KEYPAD.id)) {
            options.force = Forces.KEYPAD;
        } else {
            options.highlightBoard = true;
        }

        steps.push(BoardTutorialHelper.getDiscoverWordStep(options));
    }
    return steps;
};

StacksTutorial.isAvailable = function () {
    var game = Game.currentGame;
    return game && game.introPlaying && game.levelWithTutorial();
};
