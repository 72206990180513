/**
 * Created by olga on 26.12.2024
 */

var BoardTutorial = function () {
    return new TutorialScenario("board_tutorial", {
        listSteps: BoardTutorial.listSteps,

        onStart: function () {
            Game.currentGame.keypad.hideShuffleButton();
        },

        onFinish: function () {
            Game.currentGame.keypad.showShuffleButton();
        },

        isAvailable: function () {
            var game = Game.currentGame;
            if (game) {
                return game.introPlaying ? game.levelWithTutorial() && BoardTutorialHelper.listTutorialWords(2).length
                    : cleverapps.isKnockoutGame() && cleverapps.knockoutTutorial.isRunningStep(2);
            }
        }
    });
};

BoardTutorial.listSteps = function () {
    var steps = [];

    BoardTutorialHelper.listTutorialWords(2).forEach(function (word, index) {
        var options = {};
        options.word = word;
        options.pathColor = cleverapps.styles.COLORS.PATH_COLOR;

        if (index === 0 && !cleverapps.forces.isShown(Forces.KEYPAD.id)) {
            options.force = Forces.KEYPAD;
        } else {
            options.highlightBoard = true;
        }

        steps.push(BoardTutorialHelper.getDiscoverWordStep(options));
    });

    steps.push(BoardTutorial.getFinishStep());

    return steps;
};

BoardTutorial.getFinishStep = function () {
    var options = {
        name: "mimial_dialogue",
        onStart: function () {
            var scene = cleverapps.scenes.getRunningScene();
            cleverapps.focusManager.setEventNodes([scene.keypadView]);
            this.dialogue = new MinimalDialogue({
                person: "king",
                delay: 2,
                text: Messages.get("TutorialFinishWindow.text", {
                    gameTitle: Messages.get(cleverapps.config.title)
                }),
                forcePosition: cleverapps.styles.WordGameScene.minimalPosition
            });
            scene.addChild(this.dialogue);
            this.dialogue.display();

            this.keypadHandler = Game.currentGame.keypad.once("touchBegan", function () {
                this.execute();
                Game.currentGame.counter.trigger();
            }.bind(this));
            this.gameHandler = Game.currentGame.once("stop", function () {
                this.execute();
                Game.currentGame.counter.trigger();
            }.bind(this));
        },

        onExecution: function () {
            this.keypadHandler.clear();
            this.keypadHandler = undefined;
            this.gameHandler.clear();
            this.gameHandler = undefined;
        },

        onFinish: function () {
            if (this.dialogue) {
                this.dialogue.remove();
                this.dialogue = undefined;
            }
        }
    };

    return new TutorialStep(options);
};
