/**
 * Created by andrey on 01.08.2022
 */

var HideAnimations = function (f) {
    if (this.outcome !== GameBase.OUTCOME_LOST) {
        cleverapps.focusManager.hideControlsWhileFocused("MenuBarGoldItem");
    }

    var bonusLevelView = cleverapps.scenes.getRunningScene().bonusLevelView;
    var items = [this.game.keypad, this.game.board, bonusLevelView].filter(Boolean);

    f = cleverapps.wait(items.length, f);
    items.forEach(function (item) {
        item.hide(f);
    });
    
    if (this.hintView) {
        this.hintView.hideAnimation();
    }
};