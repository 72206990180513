/**
 * Created by slava on 24.03.17.
 */

cleverapps.Extra = function (words, foundWords) {
    this.levelContentExtra = words || [];
    this.oldFounds = [];
    this.foundWordsSet = {};
    this.foundList = [];

    this.onChangedListener = function () {};

    this.wordsSet = this.createSet();

    foundWords.forEach(function (found) {
        if (this.wordsSet[found] !== undefined) {
            this.foundWordsSet[found] = true;
            this.foundList.push(found);
        } else {
            this.oldFounds.push(found);
        }
    }, this);
};

cleverapps.Extra.prototype.createSet = function () {
    var dictionary = bundles["extra_" + cleverapps.settings.language] && bundles["extra_" + cleverapps.settings.language].jsons.extra_dictionary_json;
    if (dictionary) {
        return cc.loader.getRes(dictionary);
    }
    return cleverapps.createSet(this.levelContentExtra);
};

cleverapps.Extra.prototype.getAllFoundWords = function () {
    return this.oldFounds.concat(Object.keys(this.foundWordsSet));
};

cleverapps.Extra.prototype.wordFound = function (word) {
    levels.cookieJar.onFoundListener(word);
};

cleverapps.Extra.prototype.discover = function (word) {
    if (!this.foundWordsSet[word]) {
        this.foundWordsSet[word] = true;
        this.foundList.push(word);
        this.onChangedListener();

        cleverapps.dailyTasks.processDiscoverWord(word);
        levels.cookieJar.incCounter();
    }
};

cleverapps.Extra.prototype.contains = function (word) {
    return this.wordsSet[word] !== undefined;
};

cleverapps.Extra.prototype.analyze = function (word) {
    if (this.foundWordsSet[word] !== undefined) {
        return WordGame.STATUSES.EXTRA_FOUND;
    }

    return WordGame.STATUSES.EXTRA;
};